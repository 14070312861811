import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

const CameraSelector = (props) => {
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);

    const handleCameraChange = (event) => {
        setDeviceId(event.target.value);
        props.onDeviceChange(event.target.value);
    }

    const handleDevices = React.useCallback(
        mediaDevices => {
            const mDevices = mediaDevices.filter(({ kind }) => kind === "videoinput")
            setDevices(mDevices)
            if (mDevices.length !== 0) {
                setDeviceId(mDevices[0].deviceId)
                props.onDeviceChange(mDevices[0].deviceId)
            }
        },
        [setDevices, props]
    );

    React.useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
        [handleDevices]
    );

    return (
        <FormControl style={{ width: "100%" }}>
            <InputLabel id="camera-selector">Camera</InputLabel>
            <Select
                labelId="camera-selector"
                id="camera-selector"
                value={deviceId}
                onChange={handleCameraChange}
            >
                {
                    devices.map((device, index) => <MenuItem key={index} value={device.deviceId}>{device.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

export default CameraSelector;