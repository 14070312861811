import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  details: {
    marginLeft: theme.spacing(2)
  }
}));

const PersonDetails = (props) => {
    const classes = useStyles();
    const icon = (isComplete) => {
        return isComplete
        ? <CheckIcon className="icon covered" />
        : <CloseIcon className="icon not-covered" />
    }

    return(
        <React.Fragment>
            <h3>PERSON - {props.personId}</h3>
            <div className={classes.details}>
                <p>{icon(props.details.bodyPartsDetected)} BODY PARTS DETECTED</p>
                <p>{icon(props.details.faceCover)} FACE COVERED</p>
                <p>{icon(props.details.handCover)} HAND COVERED</p>
                <p>{icon(props.details.headCover)} HEAD COVERED</p>
            </div>
        </React.Fragment>
    )
}

export default PersonDetails;