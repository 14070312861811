import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none'
    }
}));

const ImageSelector = (props) => {
    const classes = useStyles();
    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            props.onSelect(e.target.result)
        };
    }

    return (
        <React.Fragment>
            <input
                accept="image/jpeg"
                className={classes.input}
                id="icon-button-photo"
                onChange={handleCapture}
                type="file"
            />
            <label htmlFor="icon-button-photo">
                <Button variant="contained" component="span" color="secondary" size="small">
                    Upload Image
                </Button>
            </label>
        </React.Fragment>
    )
}

export default ImageSelector;