import './App.css';
import React from "react";
import PPEDetector from "./components/PPEDetector";
import { Container, Grid } from '@material-ui/core';
import PersonDetails from './components/PersonDetails';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function App() {
  const classes = useStyles();
  const [personDetectedList, setPersonDetectedList] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const onImageCapture = (imageSrc) => {
    setOpenBackdrop(true);
  }
  const onSuccess = (response) => {
    const persons = [];
    response.persons.forEach((person) => {
      const personInfo = {
        bodyPartsDetected: true,
        faceCover: false,
        headCover: false,
        handCover: false
      }

      person.bodyParts.forEach((part) => {
        part.equipmentDetections.forEach((equipment) => {
          const type = equipment.type.toLowerCase();
          switch (type) {
            case "head_cover": personInfo.headCover = true; break;
            case "face_cover": personInfo.faceCover = true; break;
            case "hand_cover": personInfo.handCover = true; break;
            default: break;
          }
        })
      })
      persons.push(personInfo);
    })

    setPersonDetectedList(persons);
    setOpenBackdrop(false);
    if (persons.length === 0) {
      alert("Unable to detect body parts. Please try again.")
    }
  }
  const onReset = () => {
    setPersonDetectedList([]);
  }
  const onError = (err) => {
    alert(err);
    setOpenBackdrop(false);
    alert("Something went wrong. Please try again.")
  }

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PPEDetector onImageCapture={onImageCapture} onSuccess={onSuccess} onError={onError} onReset={onReset} />
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={1}>
        {
          personDetectedList.map((details, index) => (
            <Grid item xs={12} sm={6} md={2}>
              <PersonDetails key={index} personId={index + 1} details={details} />
            </Grid>
          ))
        }
      </Grid>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default App;
