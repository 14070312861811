import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider as ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const commonStyles = {
  typography: {
      fontFamily: [
          'Comfortaa', 'cursive'
      ].join(',')
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={createMuiTheme(commonStyles)}>
      <CssBaseline/>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
