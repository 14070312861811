import React from "react";
import WebCam from "react-webcam";
import Button from "@material-ui/core/Button";
import { Card, CardActions, CardContent, Grid } from "@material-ui/core";
import CameraSelector from "./CameraSelector";
import ImageSelector from "./ImageSelector";

const PPEDetector = (props) => {
  const imageCanvasRef = React.useRef(null);
  const [canvasSize, setCanvasSize] = React.useState({
    width: "0px", height: "0px"
  })
  const [showWebCam, setShowWebCam] = React.useState(true);
  const [done, setDone] = React.useState(false);
  const [deviceId, setDeviceId] = React.useState(null);
  const webcamRef = React.useRef(null);

  const onDeviceChange = (deviceId) => {
    setDeviceId(deviceId);
  }
  const reset = () => {
    setShowWebCam(true)
    setDone(false)
    setCanvasSize({
      width: "0px", height: "0px"
    });
    props.onReset();
  }
  const onImageSelect = (img) => {
    detectPPE(img);
  }

  const cameraAndDetectPPEButton = () => {
    return (
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={6} md={8}>
            <CameraSelector onDeviceChange={onDeviceChange} />
          </Grid>
          <Grid item xs={6} md={4}></Grid>
          <Grid item xs={6}>
            <Button onClick={detectPPEUsingCameraImage} color="primary" variant="contained" size="small">
              Detect PPE
            </Button>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
              <ImageSelector onSelect={onImageSelect} />
          </Grid>
        </Grid>
    )
  }
  const tryAgainButton = () => {
    return (
      <Button onClick={reset} size="small" variant="contained" color="secondary">
        TRY AGAIN
      </Button>
    )
  }
  const drawImage = (src) => {
    const canvas = imageCanvasRef.current
    const context = canvas.getContext('2d')

    const image = new Image();
    image.onload = () => {
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    }
    image.src = src;
  }
  const markObjectsOnImage = (persons) => {
    const canvas = imageCanvasRef.current;
    const context = canvas.getContext('2d');
    const objectMarkingColor = (object) => {
      switch (object.toLowerCase()) {
        case "head_cover": return "yellow";
        case "face_cover": return "green";
        case "hand_cover": return "orange";
        default: break;
      }
    }
    const drawRect = (box, strokeStyle) => {
      context.beginPath();
      context.rect(box.left * canvas.width, box.top * canvas.height, box.width * canvas.width, box.height * canvas.height);
      context.strokeStyle = strokeStyle;
      context.stroke();
    }

    persons.forEach((person) => {
      drawRect(person.boundingBox, "white");
      person.bodyParts.forEach((part) => {
        part.equipmentDetections.forEach((equipment) => {
          drawRect(equipment.boundingBox, objectMarkingColor(equipment.type));
        })
      })
    })
  }

  const detectPPE =  React.useCallback((imgSrc) => {
    setDone(false);
    setShowWebCam(false);

    setCanvasSize({
      width: "100%", height: "100%"
    });
    drawImage(imgSrc);

    props.onImageCapture(imgSrc);

    fetch(process.env.REACT_APP_PPE_API_BASE_URL + "/detect", {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json"
      },
      "body": JSON.stringify({
        image: imgSrc
      })
    })
      .then(response => response.json())
      .then(response => {
        setDone(true);
        markObjectsOnImage(response.persons);
        props.onSuccess(response);
      })
      .catch(err => {
        setDone(true);
        props.onError(err);
      });
  }, [props])
  const detectPPEUsingCameraImage = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({ mirrored: true });

    detectPPE(imageSrc);
  }, [detectPPE, webcamRef]);

  return (
    <Card>
      <CardContent>
        <div>
          {showWebCam && <WebCam width="100%" height="100%" audio={false} ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={{ deviceId: deviceId }} />}
        </div>
        <canvas style={{ width: canvasSize.width, height: canvasSize.height }} ref={imageCanvasRef} />
      </CardContent>
      <CardActions disableSpacing>
        {done ? tryAgainButton() : cameraAndDetectPPEButton()}
      </CardActions>
    </Card>
  )
}

export default PPEDetector;